import React, { useRef } from "react"
import tw from "twin.macro"
import { TooltipProps } from "./Tooltip.d"

const Tooltip: React.FC<TooltipProps> = ({
  children,
  fromBanner = false,
  fromTopNav = false,
  fromToyotaLogo = false,
  fromAccessoriesNav = false,
  fromInventoryCard = false,
  fromInventoryCardSwatch = false,
  parentLeft = null,
  parentRight = null,
  color = "red",
  active = false,
  ...remainingProps
}): JSX.Element => {
  const popOverRef = useRef(null)
  const pointerRef = useRef(null)

  return (
    <div
      ref={popOverRef}
      css={[
        tw`absolute top-[100%] px-2 py-1.5 bg-red-400 rounded text-white text-center text-sm mt-[20px] z-[50]`,
        fromBanner && tw`lg:(w-[320px])`,
        fromTopNav && tw`bg-gray-900 -mt-2 w-48 -right-[75px]`,
        fromToyotaLogo && tw`bg-gray-900 mt-2 w-72 text-xs text-left`,
        fromInventoryCard &&
          tw`bg-black mt-2 w-72 text-xs text-left -left-0 md:(-left-10)`,
        fromInventoryCardSwatch &&
          tw`bg-black top-[-0px] w-52 text-xs mt-[10px] text-left -left-56 border-gray-700 border overflow-visible z-[2]`,
        color === "black" && tw`bg-black`,
      ]}
      {...remainingProps}
    >
      <div
        ref={pointerRef}
        css={[
          `border-left: 10px solid transparent; border-right: 10px solid transparent; border-bottom: 10px solid;`,
          tw`absolute bottom-[100%] left-[50%] ml-[-10px] border-b-red-400`,
          fromBanner && tw`left-[10%]`,
          fromTopNav && tw`border-b-gray-900`,
          fromToyotaLogo && tw`border-b-gray-900 left-[56px]`,
          fromInventoryCard && tw`border-b-red-300 left-[36%] md:(left-[50%])`,
          color === "black" && tw`border-b-black`,
          fromInventoryCardSwatch &&
            tw`left-[100%] right-0 top-[35%] ml-[-5px] border-b-gray-700 transform rotate-90 z-[1]`,
          fromAccessoriesNav && tw`border-b-gray-50`,
        ]}
      />
      {children}
    </div>
  )
}

export default Tooltip
